import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/legal-policy"

const TermsConditions = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout isGrayMain>
      <Breadcrumbs title="Regulamin" />
      <Content pageData={pageData} />
      <BanerContact />
    </Layout>
  )
}

export const Head = () => <Seo title="Regulamin" />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo1Mg==" } }) {
      nodes {
        title
        content
      }
    }
  }
`

export default TermsConditions

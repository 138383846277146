import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const Content = ({ pageData }) => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <motion.h1 {...fadeAnimation}>{pageData?.title}</motion.h1>
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <motion.div
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
              {...fadeAnimation}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
